import React, { memo } from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';

import { SEO, Card } from '@components';

import './tags.scss';

const TagsPage = ({ data }) => {
  const {
    allMarkdownRemark: { group },
  } = data;

  return (
    <div>
      <SEO title="Tags" />
      <h2 className="on-tags-page-title">Tags</h2>
      <div className="on-tags-page-main on-tags-page-grid-container">
        {group.map(tag => (
          <div className="on-tags-page-item" key={tag.fieldValue}>
            <Link className="on-tags-page-item" to={`/tags/${kebabCase(tag.fieldValue)}`}>
              <Card className="on-grid-container ">
                <span className="circle">{tag.totalCount}</span>
                <span className="text">
                  <h3>{tag.fieldValue}</h3>
                </span>
              </Card>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired,
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
};

export const pageQuery = graphql`
  query {
    ...BlogTagsPageQueryFragment
  }
`;

export default memo(TagsPage);
